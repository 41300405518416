<template>
  <div class="criterias-view">
    <div class="scene-name-edit">
      <small>Scene name</small>
      <input
        type="text"
        @change="SetSceneName($event)"
        :value="CriteriasLocal.name"
      />
    </div>

    <div class="criterias-list">
      <div class="criterias-add">
        <h2>
          <span class="material-icons">edit_note</span>
          Criterias
        </h2>
        <div class="btns-img-criterias-add">
          <p style="opacity: 50%">{{ GetLengthImages }} ref. images</p>
          <button class="add-images" @click="modalImages = true">
            <span class="material-icons">add</span>
            Add images
          </button>
          <button @click="AddCriteria">
            <span class="material-icons">add</span>
            Add criteria
          </button>
        </div>
      </div>

      <div class="criterias" ref="criterias">
        <div
          class="criteria"
          v-for="(criteria, index) in CriteriasLocal.criterias"
          :key="criteria"
        >
          <div class="criteria-input">
            <small>Criteria</small>
            <input
              type="text"
              @change="setCriteria(index, $event)"
              :value="criteria"
              placeholder="Criteria..."
            />
          </div>
          <button @click="removeCriteria(index)" class="delete-btn">
            <span class="material-icons">delete</span>
          </button>
        </div>
      </div>
    </div>

    <div class="btns-delete">
      <button class="delete-btn" @click="$emit('removeScene')">
        <span class="material-icons">delete</span> Delete scene
      </button>
    </div>
  </div>

  <ModalImages
    v-if="modalImages"
    :images="CriteriasLocal.images"
    @addImages="addImages"
    @closeModal="modalImages = false"
  />
</template>

<script>
import ModalImages from "./ModalImages.vue";

export default {
  data() {
    return {
      CriteriasLocal: Array,
      modalImages: false,
      imagesLocal: Array,
    };
  },

  components: {
    ModalImages,
  },

  props: ["scene", "indexScene"],

  mounted: function () {
    this.CriteriasLocal = this.scene;
    this.imagesLocal = this.CriteriasLocal.images;
  },

  updated: function () {
    this.CriteriasLocal = this.scene;
    this.imagesLocal = this.CriteriasLocal.images;
  },
  emits: ["saveTest"],

  methods: {
    AddCriteria: function () {
      this.CriteriasLocal.criterias.push("");
      this.$refs["criterias"].scrollTop = this.$refs["criterias"].scrollHeight;
    },
    removeCriteria: function (index) {
      this.CriteriasLocal.criterias.splice(index, 1);
      this.$emit("saveTest", this.CriteriasLocal);
    },
    setCriteria: function (index, e) {
      this.CriteriasLocal.criterias[index] = e.target.value;
      this.$emit("saveTest", this.CriteriasLocal);
    },

    SetSceneName: function (e) {
      this.CriteriasLocal.name = e.target.value;
      this.$emit("saveTest", this.CriteriasLocal);
    },

    addImages(data) {
      console.log(data);
      this.CriteriasLocal.images = [];
      var TestName = this.$store.state.tests[this.$route.params.id].name;

      data.forEach((element) => {
        this.CriteriasLocal.images.push({
          folder: TestName,
          url: element.previewURL,
          imageName: element.name,
        });
      });

      this.$emit("saveTest", this.CriteriasLocal);
    },
  },

  computed: {
    GetLengthImages() {
      return this.imagesLocal.length;
    },
  },
};
</script>

<style lang="scss">
.criterias-view {
  display: flex;
  flex-direction: column;
  padding: 10px;

  small {
    position: relative;
    top: -30px;
    left: 15px;
    background: #202020;
    padding-left: 5px;
    padding-right: 5px;
    color: white;
    width: 95px;
    text-align: center;
  }

  .scene-name-edit {
    margin-top: 20px;
    display: flex;
    align-items: center;
    height: 56px;
    border: 1px solid white;
    border-radius: 5px;

    input {
      position: relative;
      height: 100%;
      width: 95%;
      background: transparent;
      border: none;
      outline: none;
      color: white;
      left: -70px;
      font-size: 16px;
    }
  }

  .criterias-list {
    margin-top: 60px;

    .criterias-add {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        display: flex;
        align-items: center;

        span {
          margin-right: 5px;
          color: #0064c8;
          font-size: 1.5em;
        }
      }

      .btns-img-criterias-add {
        display: flex;
        align-items: center;
        .add-images {
          margin-right: 10px;
          background: transparent;
          border: 2px solid #0064c8;
        }
      }

      p {
        margin-right: 5px;
      }

      button {
        height: 42px;
        background: #0064c8;
        border: none;
        width: 168px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        color: white;
        font-size: 16px;
      }
    }

    .criterias {
      position: absolute;
      overflow-y: overlay;
      padding: 10px;
      left: 0;
      right: 0;
      bottom: 70px;
      top: 210px;
    }

    .criteria {
      display: grid;
      grid-template-columns: 14fr 1fr;
      grid-gap: 10px;
      margin-bottom: 15px;
      .criteria-input {
        height: 56px;
        border: 1px solid white;
        border-radius: 5px;

        small {
          top: -10px;
        }

        input {
          position: relative;
          top: -17px;
          height: 90%;
          background: transparent;
          border: none;
          color: white;
          font-size: 16px;
          width: 95%;
          outline: none;
          padding-left: 17px;
        }
      }
    }
  }

  .btns-delete {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    bottom: 10px;
    right: 10px;

    button {
      width: 140px;
      font-weight: 600;
    }
  }

  .delete-btn {
    width: 58px;
    height: 58px;
    background: #202020;
    border: 2px solid #dc3548;
    border-radius: 5px;
    color: #dc3548;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
