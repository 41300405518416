<template>
  <div class="report-detail">
    <div class="top-report-detail">
      <h2>{{ scene.name }}</h2>
    </div>

    <div class="list-criterias">
      <div
        class="criteria-report"
        v-for="criteria in scene.criterias"
        :key="criteria"
      >
        <button
          class="comment-report"
          @click="
            if (criteria.comment != null) {
              ModalComment = true;
            }
            CriteriaForComment = criteria.criteria;
            comment = criteria.comment;
            CommentValidity = criteria.valid;
          "
          v-bind:class="{ hasComment: criteria.comment != null }"
        >
          <span class="material-icons">comment</span>
        </button>
        <div class="criteria-infos">
          <p>{{ criteria.criteria }}</p>
          <div class="infos-valid" v-if="criteria.valid == true">
            PASSED
            <span class="material-icons">check_circle</span>
          </div>

          <div class="infos-reject" v-if="criteria.valid == false">
            FAIL
            <span class="material-icons">do_not_disturb</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" v-if="ModalComment">
    <div class="modal-com">
      <div class="modal-top">
        <h2>
          {{ CriteriaForComment }}
        </h2>
        <span class="material-icons close-btn" @click="ModalComment = false"
          >close</span
        >
      </div>

      <div>
        <p class="comment-p">{{ comment }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ModalComment: false,
      comment: String,
      CriteriaForComment: String,
      CommentValidity: Boolean,
    };
  },

  props: ["scene"],

  mounted: function () {
    console.log(this.scene);
  },

  updated: function () {
    console.log(this.scene);
  },
};
</script>

<style lang="scss">
.report-detail {
  height: 100%;
  background: #202020;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  overflow: hidden;

  .top-report-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .list-criterias {
    position: absolute;
    left: 10px;
    right: 10px;
    overflow: scroll;
    bottom: 10px;
    top: 100px;

    .criteria-report {
      display: grid;
      grid-template-columns: 58px 1fr;
      grid-gap: 5px;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;

      .comment-report {
        width: 0px;
        height: 0px;
        background: transparent;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: none;
          color: #0064c8;
        }
      }

      .hasComment {
        display: block;
        background: #0064c8;
        height: 58px;
        width: 58px;

        span {
          display: block;
          color: white;
        }
      }

      .criteria-infos {
        display: flex;
        align-items: center;
        background: white;
        color: black;
        border-radius: 5px;
        height: 58px;
        justify-content: space-between;
        padding: 0 15px;
        .infos-valid {
          color: #2ebc70;
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 16px;

          span {
            margin-left: 10px;
            font-size: 2em;
          }
        }

        .infos-reject {
          color: #dc3548;
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 16px;

          span {
            margin-left: 10px;
            font-size: 2em;
          }
        }
      }
    }
  }
}

.modal-com {
  width: 40vw;
  height: 35vh;
  background: #202020;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  display: grid;
  grid-template-rows: 1fr 3fr;

  .modal-top {
    h2 {
      font-size: 1.5em;
    }
  }

  .comment-p {
    background: white;
    color: black;
    border-radius: 5px;
    height: 80%;
    overflow: auto;
    padding: 10px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}
</style>
