<template>
  <div class="dashboard">
    <div class="infos">
      <div class="infos-name">
        <div class="logo-test"></div>
        <div class="test-infos">
          <h1>{{ test.name }}</h1>
          <div class="infos-p">
            <p>
              <span class="material-icons">tv</span>

              {{ test.template }}
            </p>

            <p>
              <span class="material-icons">person</span>

              {{ test.created_by }}
            </p>
          </div>
        </div>
      </div>

      <div class="btns-test">
        <button @click="deleteModal = true" class="btn-delete">
          <span class="material-icons">delete</span>
        </button>
        <button
          @click="
            $router.push({
              name: 'edit',
              params: { id: $route.params.id, rapportImport: null },
            })
          "
          class="btn-edit-test"
        >
          <span class="material-icons">edit</span>
          Edit
        </button>

        <button class="btn-save" @click="saveJSON">
          <span class="material-icons">save_alt</span> Export
        </button>
      </div>
    </div>

    <div class="bottom-side">
      <div class="scenes-list">
        <div class="top">
          <h1>Scenes</h1>

          <div class="scenes-btns">
            <button class="btn-zip" @click="createZip()">ZIP</button>
            <button
              v-if="
                typeof test.scenes !== 'undefined' && test.scenes.length > 0
              "
              @click="
                $router.push({
                  name: 'runner',
                  params: { id: $route.params.id },
                })
              "
            >
              <span class="material-icons">play_arrow</span>
              Launch
            </button>

            <button
              v-else
              @click="
                $router.push({
                  name: 'runner',
                  params: { id: $route.params.id },
                })
              "
              style="background: grey"
              disabled
            >
              <span class="material-icons">play_arrow</span>
              Launch
            </button>
          </div>
        </div>

        <div
          class="no-scene"
          v-if="typeof test.scenes !== 'undefined' && test.scenes.length == 0"
        >
          <h1>No scenes yet</h1>
        </div>

        <div class="scenes-listage" v-else>
          <div
            class="scenes-dashboard"
            v-for="scene in test.scenes"
            :key="scene"
          >
            <h3>{{ scene.name }}</h3>
            <div class="scene">
              <p
                class="criterias-scene"
                v-for="(criteria, index) in scene.criterias"
                :key="criteria"
              >
                Critère {{ index + 1 }} - {{ criteria }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <HistoryView :indexTest="$route.params.id" />
    </div>
  </div>

  <div class="modal" v-if="deleteModal">
    <div class="modal-confirmation-delete">
      <div class="modal-top">
        <h2>Confirm deletion</h2>
        <span class="material-icons close-btn" @click="deleteModal = false"
          >close</span
        >
      </div>

      <div class="modal-btns-deletion">
        <button @click="removeTest" class="delete-btn">
          <span class="material-icons">delete</span> Delete
        </button>
        <button @click="deleteModal = false" class="cancel-btn">
          <span class="material-icons">cancel</span> Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HistoryView from "../components/HistoryView.vue";
import JSZip from "jszip";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      test: Array,
      deleteModal: false,
    };
  },

  mounted: function () {
    this.test = this.$store.state.tests[this.$route.params.id];
    console.log(this.test);
  },

  updated: function () {
    this.test = this.$store.state.tests[this.$route.params.id];
  },

  components: {
    HistoryView,
  },

  methods: {
    removeTest: function () {
      var i = 0;
      this.SelectedRapports.forEach((rapport, index) => {
        this.$store.dispatch("removeRapport", index - i);
        i++;
      });
      this.$store.dispatch("removeTest", this.$route.params.id);
      this.$router.push({ path: "/" });
    },

    saveJSON: function () {
      var FileSaver = require("file-saver");
      var json = {
        test: this.test,
        rapports: this.SelectedRapports,
      };

      var blob = new Blob([JSON.stringify(json)], {
        type: "application/json;charset=utf-8",
      });
      FileSaver.saveAs(blob, this.test.name + ".json");
    },

    createZip: async function () {
      let zip = new JSZip();
      let folder = zip.folder(`${this.test.name}`);
      this.test.scenes.forEach((scene) => {
        scene.images.forEach((image) => {
          const imageUrl = image.url;
          const blobPromise = fetch(imageUrl).then((r) => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
          });

          folder.file(`${image.imageName}`, blobPromise);
        });
      });

      zip
        .generateAsync({ type: "blob" })
        .then((blob) => saveAs(blob, `${this.test.name}`))
        .catch((e) => console.log(e));
    },
  },

  computed: {
    SelectedRapports: function () {
      return this.$store.state.rapports.filter(
        (rapport) => rapport[0].id_test == this.$route.params.id
      );
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  .infos {
    background: #202020;
    width: 100%;
    height: 96px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .infos-name {
      display: flex;
      align-items: center;

      .logo-test {
        width: 64px;
        height: 64px;
        border-radius: 5px;
        margin-left: 10px;
        background: #0064c8;
      }

      .test-infos {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        h1 {
          margin: 0;
        }

        .infos-p {
          display: flex;
          align-items: center;
          p {
            margin: 0;
            line-height: 20px;
            color: lightgray;
            font-size: 0.8em;
            margin-right: 10px;
            display: flex;
            align-items: center;

            span {
              color: #0064c8;
              font-size: 15px;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .btns-test {
      display: flex;
      button {
        margin-right: 10px;
        cursor: pointer;
        height: 58px;
      }

      .btn-edit-test {
        border: none;
        border-radius: 5px;
        background: #0064c8;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        width: 160px;
        svg {
          width: 25px;
          margin-right: 10px;
        }
      }

      .btn-delete {
        width: 58px;
        background: transparent;
        border: 1px solid #dc3548;
        border-radius: 5px;
        color: #dc3548;
        font-size: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn-save {
        border: none;
        background: transparent;
        color: white;
        border: 2px solid #0064c8;
        border-radius: 5px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-right: 5px;
        }
      }
    }
  }

  .bottom-side {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    grid-gap: 24px;
    margin-top: 24px;
    position: relative;
    .scenes-list {
      background: #202020;
      border-radius: 5px;
      padding: 10px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;

      .no-scene {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80%;
        opacity: 50%;
      }

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .scenes-btns {
          display: flex;
          .btn-zip {
            height: 58px;
            border: 2px solid #0064c8;
            background: transparent;
            color: #0064c8;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            width: 58px;
            font-size: 16px;
            cursor: pointer;
            color: white;
            text-decoration: none;
            margin-right: 5px;
          }
        }

        button {
          height: 58px;
          border: none;
          background: #0064c8;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          width: 160px;
          font-size: 16px;
          cursor: pointer;

          svg {
            margin-right: 10px;
          }
        }

        button:hover {
          opacity: 90%;
        }
      }

      .scenes-listage {
        max-height: 100%;
        overflow-y: overlay;
        position: absolute;
        top: 100px;
        bottom: 0;
        overflow-x: hidden;
        right: 0;
        left: 10px;
      }

      .scenes-dashboard {
        .scene {
          border-left: 2px solid #0064c8;
          padding-left: 15px;

          .criterias-scene {
            opacity: 50%;
          }
        }
      }
    }
  }
}

.modal {
  .modal-confirmation-delete {
    height: 30vh;
    width: 35vw;
    background: #202020;
    padding: 20px;
    border-radius: 5px;
    position: relative;

    .modal-btns-deletion {
      margin-top: 100px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 12px;
      button {
        height: 58px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-right: 5px;
        }
      }

      .delete-btn {
        background: #dc3548;
        color: white;
        border: none;
      }

      .cancel-btn {
        border: 2px solid #dc3548;
        color: white;
        background: transparent;
      }
    }
  }
}
</style>
