<template>
  <div class="list-view">
    <div class="import">
      <h1>Test tool</h1>
      <label for="file-input">
        <span class="material-icons">upload</span> Import</label
      >
      <input type="file" id="file-input" @change="importJSON($event)" />
    </div>
    <div class="filters">
      <div class="search">
        <small>Search by key word</small>

        <span class="material-icons">search</span>
        <input type="text" v-model="search" placeholder="Search..." />
      </div>
    </div>

    <div class="tests-list">
      <div
        class="test"
        v-for="(test, index) in filteredList"
        :key="test"
        @click="
          $router.push({ name: 'dashboard', params: { id: index } });
          SelectedTest = index;
        "
        v-bind:class="{ selected: isSelected(index) }"
      >
        <div class="logo-test"></div>

        <div class="infos-test">
          <h3>{{ test.name }}</h3>
          <p>
            <span class="material-icons">tv</span>
            {{ test.template }}
          </p>

          <p>
            <span class="material-icons">person</span>

            {{ test.created_by }}
          </p>
        </div>
      </div>
    </div>
    <div class="add-btn">
      <button class="add-test-btn" @click="newModal = true">
        <span class="material-icons">add</span> Add Test
      </button>
    </div>
  </div>

  <ModalNew v-if="newModal" @closeModalNew="newModal = false" />
</template>

<script>
import ModalNew from "./ModalNew.vue";
export default {
  data() {
    return {
      search: "",
      SelectedTest: null,
      newModal: false,
    };
  },

  components: {
    ModalNew,
  },

  mounted: function () {
    if (this.$route.params.id) {
      console.log(this.$route.params.id);
      this.SelectedTest == this.$route.params.id;
    }
  },

  methods: {
    changeVue(index) {
      this.$emit("changeVue", { vue: "Dashboard", test: index });
      this.SelectedTest = index;
    },

    addTest: function () {
      this.$emit("addTest");
    },

    isSelected: function (index) {
      if (this.SelectedTest == index) {
        return true;
      } else {
        return false;
      }
    },

    importJSON: function (e) {
      const files = e.target.files;
      if (files.length <= 0) {
        return false;
      }

      const fr = new FileReader();

      fr.onload = (ev) => {
        const result = JSON.parse(ev.target.result);
        this.$store.dispatch("importTest", result);
      };
      fr.readAsText(files.item(0));
    },
  },

  computed: {
    filteredList() {
      return this.$store.state.tests.filter((post) => {
        return post.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style lang="scss">
.list-view {
  background: #202020;
  border-radius: 5px;
  color: white;
  margin: 24px 0 24px 24px;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  .import {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      display: none;
    }

    label {
      cursor: pointer;
      background: #0064c8;
      color: white;
      height: 58px;
      display: flex;
      width: 100px;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
    }

    label:hover {
      opacity: 90%;
    }
  }

  .filters {
    .search {
      height: 56px;
      width: 100%;
      border: 1px solid white;
      border-radius: 5px;
      display: flex;
      align-items: center;

      small {
        background: #202020;
        margin-left: 15px;
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        top: -30px;
        width: 280px;
        text-align: center;
      }

      span {
        position: relative;
        left: -148px;
        width: 29px;
      }

      input {
        position: relative;
        left: -140px;
        background: transparent;
        border: none;
        height: 56px;
        outline: none;
        font-size: 1.3em;
        color: white;
        width: 100%;
      }
    }
  }

  .tests-list {
    margin-top: 10px;
    overflow-y: overlay;
    height: 100%;
    .test {
      position: relative;
      height: 96px;
      border-radius: 5px;
      background: #101010;
      border: 1px solid #404040;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      transition: all 0.2s ease;
      cursor: pointer;

      .logo-test {
        background: #0064c8;
        width: 64px;
        height: 64px;
        border-radius: 5px;
        margin-left: 10px;
      }

      .infos-test {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        h3 {
          margin: 0;
        }

        p {
          margin: 0;
          line-height: 20px;
          color: lightgray;
          font-size: 0.8em;
          display: flex;
          align-items: center;

          span {
            margin-right: 5px;
            font-size: 15px;
            color: #0064c8;
          }
        }
      }
    }

    .selected {
      background: white;
      color: black;
    }
  }

  .add-btn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    bottom: 10px;
    right: 10px;

    button {
      height: 56px;
      width: 150px;
      border: none;
      background: #2ebc70;
      font-size: 16px;
      color: white;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
