import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "../views/DashboardView.vue";
import EditView from "../views/EditView.vue";
import LaunchView from "../views/LaunchView.vue";
import ReportView from "../views/ReportView.vue";
const routes = [
  { path: "/dashboard/:id", component: DashboardView, name: "dashboard" },
  { path: "/edit/:id", component: EditView, name: "edit" },
  { path: "/runner/:id", component: LaunchView, name: "runner", props: true },
  {
    path: "/report/:indexTest/:id",
    component: ReportView,
    name: "report",
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
