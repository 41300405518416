<template>
  <div class="scenes-edit">
    <div class="top-scenes-edit">
      <h1>Scenes</h1>
      <button @click="addScene" v-if="editable">
        <span class="material-icons">add</span>

        Add scene
      </button>
    </div>

    <div class="scenes">
      <div
        class="scene"
        @click="SelectScene(index)"
        v-for="(scene, index) in test.scenes"
        :key="scene"
        v-bind:class="{ selected: isSceneSelected(index) }"
      >
        <h3>{{ scene.name }}</h3>

        <div class="info-scene-edit">
          <p>
            <span class="material-icons">edit_note</span>
            {{ scene.criterias.length }} criterias

            <span class="scene-count" v-if="report">
              {{ countPassed(index) }}
              <span class="material-icons pass">check_circle</span>

              {{ countReject(index) }}

              <span class="material-icons reject">do_not_disturb</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SceneSelected: null,
    };
  },

  props: ["test", "indexTest", "editable", "report"],

  methods: {
    isSceneSelected: function (index) {
      if (this.SceneSelected == index) {
        return true;
      } else {
        return false;
      }
    },

    SelectScene: function (index) {
      this.$emit("changeScene", index);
      this.SceneSelected = index;
    },

    addScene: function () {
      this.$emit("addScene");
    },

    countPassed: function (index) {
      var passed = 0;
      this.test.scenes[index].criterias.forEach((criteria) => {
        if (criteria.valid) {
          passed++;
        }
      });

      return passed;
    },

    countReject: function (index) {
      var rejected = 0;
      this.test.scenes[index].criterias.forEach((criteria) => {
        if (!criteria.valid) {
          rejected++;
        }
      });

      return rejected;
    },
  },
};
</script>

<style lang="scss">
.scenes-edit {
  background: #202020;
  border-radius: 5px;
  padding: 0 10px;
  position: relative;
  overflow: hidden;

  .top-scenes-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    button {
      height: 42px;
      width: 168px;
      color: white;
      background: #0064c8;
      border: none;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
    }
  }
}

.scenes {
  position: absolute;
  width: 95%;
  bottom: 0;
  overflow-y: overlay;
  top: 100px;
  overflow-x: hidden;
  .scene {
    width: 100%;
    height: 73px;
    background: #101010;
    border: 1px solid #404040;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: all 0.2s ease;
    cursor: pointer;

    h3 {
      margin: 0;
      margin-top: 10px;
      margin-left: 15px;
      font-size: 1em;
    }

    .info-scene-edit {
      margin-left: 15px;
      margin-top: 5px;
      p {
        color: lightgray;
        margin: 0;
        font-size: 0.8em;
        display: flex;
        align-items: center;

        .material-icons {
          color: #0064c8;
          font-size: 20px;
          margin-right: 5px;
        }

        .scene-count {
          display: flex;
          align-items: center;
          margin-left: 5px;

          .pass {
            color: #2ebc70;
            font-size: 16px;
            margin-left: 5px;
          }

          .reject {
            color: #dc3548;
            font-size: 16px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  .selected {
    background: white;
    color: black;
  }
}
</style>
