<template>
  <div class="report">
    <div class="infos">
      <div class="infos-name">
        <div class="logo-test"></div>
        <div class="test-infos">
          <h1>{{ test.name }}</h1>
          <div class="infos-p">
            <p>
              <span class="material-icons">tv</span>

              {{ test.template }}
            </p>

            <p>
              <span class="material-icons">person</span>

              {{ test.created_by }}
            </p>
          </div>
        </div>
      </div>

      <div class="infos-version">
        Engine version: <strong>{{ rapport.engine }}</strong> | Server version:
        <strong>{{ rapport.server }}</strong>

        <button
          v-if="
            typeof rapport.commentGlobal != 'undefined' &&
            rapport.commentGlobal != ''
          "
          class="btn-view-comment-global"
          @click="modalGlobalCommentView = true"
        >
          View Global Comment
        </button>

        <button v-else class="btn-view-comment-global" style="background: grey">
          View Global Comment
        </button>
      </div>
    </div>

    <div class="bottom-report">
      <ScenesEdit
        :test="rapport"
        :editable="false"
        :report="true"
        @changeScene="
          (index) => {
            SceneSelected = index;
          }
        "
      />
      <ReportDetail
        v-if="SceneSelected != null"
        :scene="rapport.scenes[SceneSelected]"
      />

      <div class="no-scene" v-else>
        <span class="material-icons">do_not_disturb_on</span>
        <h2>No scene selected</h2>
      </div>
    </div>
  </div>

  <div class="modal" v-if="modalGlobalCommentView">
    <div class="modal-view-global-comment">
      <div class="modal-top">
        <h1>Global Comment</h1>
        <span
          class="material-icons close-btn"
          @click="modalGlobalCommentView = false"
          >close</span
        >
      </div>

      <div class="view-comment">
        <p>{{ rapport.commentGlobal }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ScenesEdit from "../components/ScenesEdit.vue";
import ReportDetail from "../components/ReportDetail.vue";
export default {
  components: {
    ScenesEdit,
    ReportDetail,
  },

  data() {
    return {
      test: this.$store.state.tests[this.$route.params.indexTest],
      SceneSelected: null,
      rapport: this.$store.state.rapports[this.$route.params.id][0],
      modalGlobalCommentView: false,
    };
  },

  mounted: function () {
    //console.log(this.rapport)
  },
};
</script>

<style lang="scss">
.report {
  display: flex;
  flex-direction: column;
  height: 100%;
  .infos {
    background: #202020;
    width: 100%;
    height: 96px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .infos-name {
      display: flex;
      align-items: center;

      .logo-test {
        width: 64px;
        height: 64px;
        border-radius: 5px;
        margin-left: 10px;
        background: #0064c8;
      }

      .test-infos {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        h1 {
          margin: 0;
        }

        .infos-p {
          display: flex;
          align-items: center;
          p {
            margin: 0;
            line-height: 20px;
            color: lightgray;
            font-size: 0.8em;
            margin-right: 10px;
            display: flex;
            align-items: center;

            span {
              color: #0064c8;
              font-size: 15px;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .infos-version {
      margin-right: 10px;

      .btn-view-comment-global {
        height: 58px;
        background: #0064c8;
        color: white;
        border: none;
        border-radius: 5px;
        margin-left: 10px;
      }
    }
  }

  .bottom-report {
    display: grid;
    height: 100%;
    grid-gap: 24px;
    margin-top: 24px;
    grid-template-columns: 1fr 2fr;

    .no-scene {
      background: #202020;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      span {
        font-size: 4em;
        opacity: 50%;
      }

      h2 {
        opacity: 50%;
      }
    }
  }
}

.modal-view-global-comment {
  height: 40vh;
  width: 50vw;
  background: #202020;
  position: relative;
  padding: 20px;
  box-sizing: border-box;

  .view-comment {
    background: white;
    border-radius: 5px;
    width: 100%;
    height: 60%;
    overflow: scroll;
    p {
      color: black;
      padding-left: 10px;
      padding-top: 10px;
      word-wrap: normal;
      margin: 0;
      white-space: pre-wrap;
    }
  }
}
</style>
