<template>
  <div class="edit">
    <div class="infos">
      <div class="infos-name">
        <div class="logo-test"></div>
        <div class="test-infos">
          <input
            class="name-input"
            type="text"
            @change="SetNameTest($event)"
            :value="TestLocal.name"
          />
          <div class="infos-p">
            <p>
              <span class="material-icons">tv</span>

              {{ TestLocal.template }}
            </p>
            <span class="material-icons">person</span>

            <input
              class="author-input"
              type="text"
              @change="SetAuthorTest($event)"
              :value="TestLocal.created_by"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-edit">
      <ScenesEdit
        :test="TestLocal"
        @addScene="addScene"
        :editable="true"
        @changeScene="changeScene"
      />

      <div class="criterias-edit" v-if="SceneSelected == null">
        <div class="no-scene">
          <span class="material-icons">do_not_disturb_on</span>
          <h2>No selected scene</h2>
        </div>
      </div>

      <div class="criterias-edit" v-else>
        <CriteriasEdit
          :scene="TestLocal.scenes[SceneSelected]"
          :indexScene="SceneSelected"
          @saveTest="saveTest"
          @removeScene="
            TestLocal.scenes.splice(SceneSelected, 1);
            SceneSelected = null;
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import CriteriasEdit from "../components/CriteriasEdit.vue";
import ScenesEdit from "../components/ScenesEdit.vue";
export default {
  data() {
    return {
      SceneSelected: null,
      TestLocal: this.$store.state.tests[this.$route.params.id],
    };
  },

  methods: {
    addScene: function () {
      this.TestLocal.scenes.push({
        criterias: [],
        name: "Scene n°" + (this.TestLocal.scenes.length + 1),
        images: [],
      });
    },

    SetNameTest: function (e) {
      this.TestLocal.name = e.target.value;
      this.$store.dispatch("editTest", {
        j: this.TestLocal,
        id: this.indexTest,
      });
    },

    SetAuthorTest: function (e) {
      this.TestLocal.created_by = e.target.value;
      this.$store.dispatch("editTest", {
        j: this.TestLocal,
        id: this.indexTest,
      });
    },

    removeScene: function () {
      this.TestLocal.scenes.splice(this.SceneSelected, 1);
      this.$store.dispatch("editTest", {
        j: this.TestLocal,
        id: this.indexTest,
      });
    },

    saveTest: function (data) {
      this.TestLocal.scenes[this.SceneSelected] = data;
      this.$store.dispatch("editTest", {
        j: this.TestLocal,
        id: this.indexTest,
      });
    },

    isSceneSelected: function (index) {
      if (this.SceneSelected == index) {
        return true;
      } else {
        return false;
      }
    },

    changeScene: function (index) {
      this.SceneSelected = index;
    },
  },

  components: {
    CriteriasEdit,
    ScenesEdit,
  },
};
</script>

<style lang="scss">
.edit {
  display: flex;
  flex-direction: column;
  height: 100%;

  button:hover {
    opacity: 90%;
    cursor: pointer;
  }
  .infos {
    background: #202020;
    width: 100%;
    height: 96px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .infos-name {
      display: flex;
      align-items: center;

      .logo-test {
        width: 64px;
        height: 64px;
        border-radius: 5px;
        margin-left: 10px;
        background: #0064c8;
      }

      .test-infos {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .name-input {
          margin: 0;
          background: transparent;
          font-size: 2em;
          font-weight: bold;
          color: white;
          border: none;
          outline: none;
          width: 500px;
          font-family: "Raleway", sans-serif;
        }

        .infos-p {
          display: flex;
          align-items: center;

          span {
            color: #0064c8;
            font-size: 15px;
            margin-right: 5px;
          }

          .author-input {
            background: transparent;
            font-size: 0.8em;
            color: lightgray;
            border: none;
            outline: none;
          }
          p {
            margin: 0;
            line-height: 20px;
            color: lightgray;
            font-size: 0.8em;
            margin-right: 10px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .btns-test {
      display: flex;
      button {
        margin-right: 10px;
        cursor: pointer;
        height: 58px;
      }

      .btn-edit-test {
        border: none;
        border-radius: 5px;
        background: #0064c8;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        width: 160px;
        svg {
          width: 25px;
          margin-right: 10px;
        }
      }

      .btn-delete {
        width: 58px;
        background: transparent;
        border: 1px solid #dc3548;
        border-radius: 5px;
      }

      button:hover {
        opacity: 90%;
      }
    }
  }

  .bottom-edit {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 100%;
    margin-top: 24px;
    grid-gap: 24px;

    .criterias-edit {
      background: #202020;
      border-radius: 5px;
      overflow: hidden;
      position: relative;

      .no-scene {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        opacity: 50%;

        span {
          font-size: 4em;
        }
        h2 {
          opacity: 50%;
        }
      }
    }
  }
}
</style>
