<template>
  <div class="modal">
    <div class="modal-comment">
      <div class="modal-top">
        <h2><span class="material-icons">comment</span> Comment</h2>
        <span class="material-icons close-btn" @click="$emit('closeModalNew')"
          >close</span
        >
      </div>

      <div class="bottom-modal-comment">
        <div class="inputs-modal">
          <div class="comment-modal">
            <small>Comment</small>
            <textarea
              v-model="newComment"
              type="text"
              placeholder="Comment..."
            />
          </div>
        </div>

        <div class="btns-modal">
          <button
            class="validate-new"
            @click="
              $emit('addComment', newComment);
              $emit('closeModalNew');
            "
          >
            <span class="material-icons">check</span> Create
          </button>
          <button class="cancel" @click="$emit('closeModalNew')">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["comment"],
  data() {
    return {
      newComment: this.comment,
    };
  },
};
</script>

<style lang="scss">
.modal {
  .modal-comment {
    width: 50vw;
    height: 60vh;
    background: #202020;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    .modal-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        display: flex;
        align-items: center;
      }

      .close-btn {
        font-size: 48px;
        cursor: pointer;
      }
    }

    .bottom-modal-comment {
      margin-top: 20px;
      height: 80%;
      display: grid;
      grid-template-rows: 2fr 1fr;

      .inputs-modal {
        display: flex;
        flex-direction: column;
        align-items: center;

        small {
          background: #202020;
          position: relative;
          top: -12px;
          padding-left: 5px;
          padding-right: 5px;
          margin-left: 15px;
        }

        .comment-modal {
          height: 100%;
          width: 100%;
          border: 1px solid white;
          border-radius: 5px;

          textarea {
            width: 99%;
            height: 95%;
            outline: none;
            background: none;
            color: white;
            border: none;
            font-size: 16px;
            padding-top: 10px;
            padding-left: 10px;
            position: relative;
            top: -20px;
          }
        }
      }

      .btns-modal {
        width: 100%;
        display: flex;
        grid-gap: 15px;
        justify-content: space-between;
        margin-top: 20px;
        button {
          width: 100%;
          height: 58px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;

          span {
            margin-right: 5px;
          }
        }

        .validate-new {
          background: #2ebc70;
          border: none;
          color: white;
        }

        .cancel {
          background: transparent;
          border: 3px solid #db3447;
          color: white;
        }
      }
    }
  }
}
</style>
