<template>
  <div class="modal">
    <div class="modal-new">
      <div class="modal-top">
        <h1>New Test</h1>
        <span class="material-icons close-btn" @click="$emit('closeModalNew')"
          >close</span
        >
      </div>

      <div class="inputs-modal">
        <div class="name-modal">
          <small>Test name</small>
          <input v-model="name" type="text" placeholder="Test name..." />
        </div>
        <div class="author-and-template">
          <div class="input-modal">
            <small>Author</small>
            <input v-model="author" type="text" placeholder="Author..." />
          </div>
          <div class="input-modal">
            <small>Template</small>
            <input v-model="template" type="text" placeholder="Template..." />
          </div>
        </div>
      </div>

      <div class="btns-modal">
        <button class="validate-new" @click="createTest">
          <span class="material-icons">check</span> Create
        </button>
        <button class="cancel" @click="$emit('closeModalNew')">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
export default {
  data() {
    return {
      name: "",
      template: "",
      author: "",
    };
  },

  methods: {
    createTest: function () {
      this.$store.dispatch("addTest", {
        created_by: this.author,
        name: this.name,
        scenes: [],
        template: this.template,
      });

      axios
        .post("https://intense-mountain-53489.herokuapp.com/folder", {
          folder: this.author,
          subfolder: this.name,
        })
        .catch(function (error) {
          console.log(error);
        });

      this.$emit("closeModalNew");
    },
  },
};
</script>

<style lang="scss">
.modal {
  .modal-new {
    width: 50vw;
    height: 60vh;
    background: #202020;
    border-radius: 5px;
    padding: 30px;
    position: relative;

    .inputs-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;

      small {
        background: #202020;
        position: relative;
        top: -12px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 15px;
      }

      .name-modal {
        height: 58px;
        width: 100%;
        border: 1px solid white;
        border-radius: 5px;

        input {
          width: 90%;
          height: 100%;
          outline: none;
          background: none;
          color: white;
          border: none;
          font-size: 16px;
          padding-left: 10px;
          position: relative;
          top: -20px;
        }
      }

      .author-and-template {
        display: flex;
        width: 100%;
        grid-gap: 15px;
        margin-top: 15px;

        .input-modal {
          height: 58px;
          width: 100%;
          border: 1px solid white;
          border-radius: 5px;

          input {
            width: 90%;
            height: 100%;
            outline: none;
            background: none;
            color: white;
            border: none;
            font-size: 16px;
            padding-left: 10px;
            position: relative;
            top: -20px;
          }
        }
      }
    }

    .btns-modal {
      width: 100%;
      display: flex;
      grid-gap: 15px;
      justify-content: space-between;
      margin-top: 100px;
      button {
        width: 100%;
        height: 58px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;

        span {
          margin-right: 5px;
        }
      }

      .validate-new {
        background: #2ebc70;
        border: none;
        color: white;
      }

      .cancel {
        background: transparent;
        border: 3px solid #db3447;
        color: white;
      }
    }
  }
}
</style>
