<template>
  <div class="runner">
    <div class="top-runner">
      <div style="display: flex; align-items: center">
        <h2>{{ scene.name }}</h2>
        <button class="btn-view-img" @click="ModalImages = true">
          View images
        </button>
      </div>

      <div class="infos-runner">
        <p>
          <span class="material-icons valid">check_circle_outline</span>
          {{ getValidLength }}
        </p>
        <p>
          <span class="material-icons reject">do_not_disturb</span>
          {{ getRejectLength }}
        </p>
      </div>
    </div>

    <div class="bottom-runner">
      <div
        class="test"
        v-for="(criteria, index) in scene.criterias"
        :key="criteria"
      >
        <button
          class="comment-btn"
          @click="
            newComment = true;
            CommentIndex = index;
            comment = rapport.scenes[indexScene].criterias[index].comment;
          "
          v-bind:class="{
            hasComment:
              rapport.scenes[indexScene].criterias[index].comment != null &&
              rapport.scenes[indexScene].criterias[index].comment != '',
          }"
        >
          <span class="material-icons">comment</span>
        </button>
        <div class="criteria-p">
          <p>{{ criteria }}</p>
        </div>
        <div class="btns-runner">
          <button
            class="valid-btn"
            @click="valid(index)"
            v-bind:class="{
              selectedValid:
                rapport.scenes[indexScene].criterias[index].valid == true,
            }"
          >
            <span class="material-icons">check_circle_outline</span>
          </button>
          <button
            class="reject-btn"
            @click="reject(index)"
            v-bind:class="{
              selectedReject:
                rapport.scenes[indexScene].criterias[index].valid == false,
            }"
          >
            <span class="material-icons">do_not_disturb</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ModalComment
    v-if="newComment"
    @closeModalNew="newComment = false"
    :comment="comment"
    @addComment="
      (comment) => {
        $emit('addComment', { comment: comment, index: CommentIndex });
      }
    "
  />

  <ModalImagesVue
    v-if="ModalImages"
    :images="scene.images"
    @closeModal="close"
  />
</template>

<script>
import ModalComment from "./ModalComment.vue";
import ModalImagesVue from "./ModalViewImages.vue";

export default {
  data() {
    return {
      newComment: false,
      CommentIndex: null,
      comment: String,
      hasComment: [],
      ModalImages: false,
    };
  },

  components: {
    ModalComment,
    ModalImagesVue,
  },

  mounted: function () {
    window.onbeforeunload = confirmExit;

    function confirmExit() {
      return "Are you sure, you want to close?";
    }
  },

  props: ["scene", "rapport", "indexScene"],

  methods: {
    valid: function (index) {
      this.$emit("addValid", index);
    },

    reject: function (index) {
      this.$emit("addReject", index);
    },

    close: function (data) {
      this.$emit("getOuts", data);
      this.ModalImages = false;
    },
  },

  computed: {
    getValidLength: function () {
      var valid = 0;
      this.rapport.scenes[this.indexScene].criterias.forEach((criteria) => {
        if (criteria.valid == true) {
          valid++;
        }
      });

      return valid;
    },

    getRejectLength: function () {
      var reject = 0;
      this.rapport.scenes[this.indexScene].criterias.forEach((criteria) => {
        if (criteria.valid == false) {
          reject++;
        }
      });

      return reject;
    },
  },
};
</script>

<style lang="scss">
.runner {
  display: flex;
  flex-direction: column;
  background: #202020;
  height: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  .top-runner {
    margin: 10px;
    display: flex;
    justify-content: space-between;

    .btn-view-img {
      height: 40px;
      width: 100px;
      margin-left: 10px;
      border: none;
      background: #0064c8;
      color: white;
      border-radius: 5px;
    }

    .infos-runner {
      display: flex;
      align-items: center;
      p {
        display: flex;
        align-items: center;
        margin-right: 15px;
        span {
          margin-right: 5px;
        }

        .valid {
          color: #2ebc70;
        }

        .reject {
          color: #dc3548;
        }
      }
    }
  }

  .bottom-runner {
    position: absolute;
    padding: 10px;
    bottom: 0;
    top: 100px;
    width: 100%;
    overflow: scroll;
    box-sizing: border-box;
    .test {
      display: grid;
      align-items: center;
      grid-template-columns: 58px 5fr 1fr;
      grid-gap: 12px;
      margin-bottom: 12px;

      button {
        width: 58px;
        height: 58px;
        background: transparent;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 24px;
        }
      }

      .comment-btn {
        border: 2px solid #0064c8;

        span {
          color: #0064c8;
        }
      }

      .hasComment {
        background: #0064c8;

        span {
          color: white;
        }
      }

      .criteria-p {
        border: 1px solid white;
        border-radius: 5px;
        height: 58px;
        display: flex;
        align-items: center;
        width: 100%;
        background: white;
        p {
          color: black;
          margin-left: 12px;
        }
      }

      .btns-runner {
        display: flex;
        justify-content: flex-end;
        .valid-btn {
          border: 2px solid #2ebc70;
          margin-right: 12px;

          span {
            color: #2ebc70;
          }
        }

        .reject-btn {
          border: 2px solid #dc3548;

          span {
            color: #dc3548;
          }
        }

        .selectedValid {
          background: #2ebc70;
          span {
            color: white;
          }
        }

        .selectedReject {
          background: #dc3548;

          span {
            color: white;
          }
        }
      }
    }
  }
}
</style>
