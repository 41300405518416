<template>
  <div class="modal">
    <div class="modal-images">
      <div class="modal-top">
        <h1>Add images</h1>
        <span class="material-icons close-btn" @click="$emit('closeModal')"
          >close</span
        >
      </div>

      <div class="upload-section">
        <div class="upload-input">
          <label for="upload">
            <span class="material-icons">monochrome_photos</span>
            {{ statusUpload }}</label
          >
          <input
            id="upload"
            multiple="multiple"
            type="file"
            accept="image/*"
            @change="onFileUpload($event)"
          />
        </div>

        <div class="view-images">
          <div
            class="image"
            v-for="(image, index) in previewImages"
            :key="image"
          >
            <img
              :src="image.previewURL"
              alt="image.png"
              @click="
                viewingImage = {
                  image: image.previewURL,
                  index: index,
                  name: image.name,
                }
              "
            />
            <small>{{ image.name }}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-image-grand" v-if="viewingImage != null">
      <img :src="viewingImage.image" @click="viewingImage = null" alt="image" />
      <button
        @click="
          previewImages.splice(viewingImage.index, 1);
          viewingImage = null;
          $emit('addImages', this.previewImages);
        "
      >
        Delete {{ viewingImage.name }}
      </button>
    </div>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  data() {
    return {
      previewImages: [],
      statusUpload: "Select images or drop them here !",
      viewingImage: null,
    };
  },
  props: ["images"],

  mounted: function () {
    this.images.forEach((image) => {
      this.previewImages.push({
        name: image.imageName,
        previewURL: image.url,
      });
    });
  },

  methods: {
    onFileUpload(event) {
      var files = event.target.files;
      this.statusUpload = "Uploading " + files.length + " files...";
      Array.from(files).forEach(async (fileObj) => {
        // Convert to base64 string
        const file = fileObj;
        const apiKey = "47fb803ae7dfbc6ba9db27b86e3ea575";
        const convertTobase64 = (file) => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
              resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
              reject(error);
            };
          });
        };

        const base64 = await convertTobase64(file);
        const url = `https://api.imgbb.com/1/upload?key=${apiKey}`;

        const formData = new FormData();
        formData.append(
          "image",
          base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
        );

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        const response = await axios.post(url, formData, config);

        const fileUrl = response.data.data.url;

        // -----------------------------------------
        // File successfully uploaded to upload.io!
        // -----------------------------------------
        console.log(`File uploaded to: ${fileUrl}`);

        this.previewImages.push({
          name: fileObj.name,
          previewURL: fileUrl,
        });

        this.statusUpload = "Select images or drop them here !";

        this.$emit("addImages", this.previewImages);
      });
    },
  },
};
</script>

<style lang="scss">
.modal-images {
  width: 60vw;
  height: 70vh;
  background: #404040;
  position: relative;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;

  .modal-top {
    button {
      height: 50px;
      width: 120px;
      border-radius: 5px;
      border: none;
      background: #2ebc70;
      color: white;
      font-size: 16px;
    }
  }

  .upload-section {
    display: grid;
    grid-template-rows: 2fr 1fr;
    position: absolute;
    bottom: 0;
    top: 100px;
    right: 0px;
    padding: 10px;
    left: 10px;
    box-sizing: border-box;
    left: 0;
    grid-gap: 24px;
    .upload-input {
      border: 2px solid white;
      border-radius: 5px;
      background: #111111;
      opacity: 50%;
      position: relative;
      input {
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }

      label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;

        span {
          margin-right: 5px;
        }
      }
    }

    .view-images {
      overflow-x: overlay;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      .image {
        width: 200px;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 5px;
        img {
          width: 100%;
          height: 60%;
          object-fit: cover;
          cursor: pointer;
        }

        img:hover {
          border: 2px solid #0064c8;
        }

        small {
          opacity: 50%;
          width: 200px;
          overflow-wrap: break-word;
        }
      }
    }

    .view-images::-webkit-scrollbar {
      display: block;
    }
  }
}

.modal-image-grand {
  position: absolute;
  width: 80vw;

  button {
    width: 100%;
    height: 58px;
    margin-top: 10px;
    background: #dc3548;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
  }

  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    cursor: pointer;
  }
}
</style>
