import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

export default createStore({
  state: {
    tests: [],
    rapports: [],
  },
  getters: {
    getTests(state) {
      return state.tests;
    },

    getOneTest(state, index) {
      return state.tests[index];
    },

    getRapports(state) {
      return state.rapports;
    },
  },
  mutations: {
    setTest(state, json) {
      state.tests.push(json);
    },

    delTest(state, index) {
      state.tests.splice(index, 1);
    },

    delRapport(state, index) {
      state.rapports.splice(index, 1);
    },

    edTest(state, data) {
      state.tests[data.id] = data.json;
    },

    setRapport(state, data) {
      state.rapports.push(data);
    },

    upRapport(state, data) {
      state.rapports[data.index] = data.rapport;
    },
  },
  actions: {
    addTest({ commit }, json) {
      commit("setTest", json);
    },

    addRapport({ commit }, json) {
      commit("setRapport", json);
    },

    removeTest({ commit }, index) {
      commit("delTest", index);
    },

    removeRapport({ commit }, index) {
      commit("delRapport", index);
    },

    editTest({ commit }, data) {
      console.log(data.j);
      commit("edTest", { json: data.j, id: data.id });
    },

    updateRapport({ commit }, data) {
      commit("upRapport", data);
    },

    importTest({ commit }, data) {
      commit("setTest", data.test);

      var test = data.test;

      test.scenes.forEach((scene) => {
        //console.log("SCENE", scene);

        if (scene.images) {
          console.log("images array already exist");
        } else {
          scene.images = [];
        }
      });

      data.rapports.forEach((rapport) => {
        rapport[0].id_test = this.getters.getTests.length - 1;
        commit("setRapport", rapport);
      });
    },
  },
  modules: {},
  plugins: [new VuexPersistence().plugin],
});
