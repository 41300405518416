<template>
  <div class="modal">
    <div class="modal-images-view">
      <div class="modal-top">
        <h1>View Images</h1>
        <small v-if="status == 0" style="color: red">Disconnected</small>
        <small v-else-if="status == 1">Connecting...</small>
        <small v-else-if="status == 2" style="color: green"
          >Connected to Next-Engine</small
        >
        <span
          class="material-icons close-btn"
          @click="$emit('closeModal', { outs: outs, layers: layers })"
          >close</span
        >
      </div>

      <div class="outs">
        <div class="out1">
          <div class="out-top">
            <h3>OUT 1</h3>
            <div class="select-btn">
              <select
                name="layer1"
                @change="sendEmpty(1, $event)"
                :value="layers[0]"
                id="layer1"
              >
                <option value="" disabled>Select a layer</option>
                <option value="1">1</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="600">600</option>
                <option value="700">700</option>
                <option value="800">800</option>
                <option value="900">900</option>
                <option value="999">999</option>
              </select>
              <button @click="MakeScreenshot(1)">
                <span class="material-icons">monochrome_photos</span>
              </button>
            </div>
          </div>
          <div class="img" v-for="image in images" :key="image">
            <img
              :src="image.url"
              @click="showImage(1, image)"
              alt=""
              v-bind:class="{ selected: isSelected(1, image) }"
            />
          </div>
        </div>

        <div class="out2">
          <div class="out-top">
            <h3>OUT 2</h3>
            <div class="select-btn">
              <select
                :value="layers[1]"
                @change="sendEmpty(2, $event)"
                name="layer2"
                id="layer2"
              >
                <option value="" disabled>Select a layer</option>
                <option value="1">1</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="600">600</option>
                <option value="700">700</option>
                <option value="800">800</option>
                <option value="900">900</option>
                <option value="999">999</option>
              </select>

              <button @click="MakeScreenshot(2)">
                <span class="material-icons">monochrome_photos</span>
              </button>
            </div>
          </div>
          <div class="img" v-for="image in images" :key="image">
            <img
              :src="image.url"
              @click="showImage(2, image)"
              alt=""
              v-bind:class="{ selected: isSelected(2, image) }"
            />
          </div>
        </div>

        <div class="out3">
          <div class="out-top">
            <h3>OUT 3</h3>
            <div class="select-btn">
              <select
                name="layer3"
                :value="layers[2]"
                @change="sendEmpty(3, $event)"
                id="layer3"
              >
                <option value="" disabled>Select a layer</option>
                <option value="1">1</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="600">600</option>
                <option value="700">700</option>
                <option value="800">800</option>
                <option value="900">900</option>
                <option value="999">999</option>
              </select>

              <button @click="MakeScreenshot(3)">
                <span class="material-icons">monochrome_photos</span>
              </button>
            </div>
          </div>
          <div class="img" v-for="image in images" :key="image">
            <img
              :src="image.url"
              @click="showImage(3, image)"
              alt=""
              v-bind:class="{ selected: isSelected(3, image) }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["images"],
  data: function () {
    return {
      connection: null,
      status: 0,
      outs: [null, null, null],
      layers: [1, 1, 1],
      screenshots: 1,
    };
  },

  created: function () {
    var vm = this;

    console.log("Starting connection to WebSocket Server");
    this.connection = new WebSocket("ws://127.0.0.1:5250/jamcp");
    vm.status = 1;
    this.connection.onmessage = function (event) {
      console.log(event);
    };

    this.connection.onopen = function (event) {
      console.log(event);
      console.log("Successfully connected to the echo websocket server...");
      vm.status = 2;
    };
  },

  methods: {
    showImage: function (out, image) {
      if (this.outs[out - 1] != null) {
        if (this.outs[out - 1] == image.imageName) {
          this.connection.send(`{
              "channel": ${out},
              "layer": ${this.layers[out - 1]},
              "command": "PLAY",
              "arguments": {
                      "clip": "empty"
                  }
              }
          }`);

          this.outs[out - 1] = null;
        } else {
          this.connection.send(`{
              "channel": ${out},
              "layer": ${this.layers[out - 1]},
              "command": "PLAY",
              "arguments": {
                      "clip": "empty"
                  }
              }
          }`);

          this.connection.send(`{
              "channel": ${out},
              "layer": ${this.layers[out - 1]},
              "command": "PLAY",
              "arguments": {
                      "clip": "${image.folder}/${image.imageName}"
                  }
              }
          }`);

          this.outs[out - 1] = image.imageName;
        }
      } else {
        this.connection.send(`{
        "channel": ${out},
        "layer": ${this.layers[out - 1]},
        "command": "PLAY",
        "arguments": {
                "clip": "${image.folder}/${image.imageName}"
            }
        }
    }`);

        this.outs[out - 1] = image.imageName;
      }
    },

    isSelected: function (out, image) {
      if (this.outs[out - 1] == image.imageName) {
        return true;
      } else {
        return false;
      }
    },

    sendEmpty: function (out, e) {
      console.log("EMPTY", out);
      this.connection.send(`{
              "channel": ${out},
              "layer": ${this.layers[out - 1]},
              "command": "PLAY",
              "arguments": {
                      "clip": "empty"
                  }
              }
          }`);

      this.layers[out - 1] = e.target.value;
    },

    MakeScreenshot: function (out) {
      this.connection.send(`{
        "channel": ${out},
        "command": "PRINT",
        "arguments": {
            "filename": "screenshot-${Date.now()}"
        }
      }`);
      this.screenshots++;
    },
  },
};
</script>

<style lang="scss">
.modal-images-view {
  position: relative;
  background: #404040;
  width: 70vw;
  height: 70vh;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;

  .outs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    position: absolute;
    right: 15px;
    left: 15px;
    bottom: 0;
    top: 100px;

    div {
      text-align: center;
      overflow: scroll;

      .out-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        .select-btn {
          display: flex;
          align-items: center;

          select {
            margin-left: 5px;
          }

          button {
            border: none;
            background: #0064c8;
            color: white;
            margin-left: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 5px;
          }
        }
      }
    }

    .img {
      overflow: scroll;

      img {
        width: 300px;
        height: 200px;
        object-fit: cover;
        margin-bottom: 10px;
      }

      .selected {
        border: 4px solid #0064c8;
      }

      .selected::after {
        content: "Activate";
      }
    }
  }
}
</style>
