<template>
  <div class="launch">
    <div class="infos">
      <div class="infos-name">
        <div class="logo-test"></div>
        <div class="test-infos">
          <h1>{{ test.name }}</h1>
          <div class="infos-p">
            <p>
              <span class="material-icons">tv</span>

              {{ test.template }}
            </p>

            <p>
              <span class="material-icons">person</span>

              {{ test.created_by }}
            </p>
          </div>
        </div>
      </div>

      <div>
        <button class="global-comment-btn" @click="modalGlobalComment = true">
          Global Comment
        </button>
      </div>
    </div>

    <div class="bottom-launch">
      <ScenesEdit
        :editable="false"
        :test="test"
        :indexTest="indexTest"
        @changeScene="selectScene"
      />

      <div class="criterias-launch" v-if="SceneSelected == null">
        <div class="no-scene">
          <span class="material-icons">do_not_disturb_on</span>
          <h2>No selected scene</h2>
        </div>
      </div>

      <div class="runner-criterias" v-else>
        <RunnerView
          @addValid="addValid"
          @addReject="addReject"
          :scene="test.scenes[SceneSelected]"
          :rapport="rapport[0]"
          :indexScene="SceneSelected"
          @addComment="addComment"
          @getOuts="getOuts"
        />

        <div class="bottom-runner-validate">
          <div>
            <span class="material-icons reject">do_not_disturb</span>
            {{ GetTotalReject }}
          </div>

          <div>
            <span class="material-icons valid">check_circle_outline</span>
            {{ GetTotalValid }}
          </div>

          <div>{{ GetTotalCriteria }}/{{ GetTotal }} criterias</div>

          <button
            class="validate-runner"
            v-if="GetTotalCriteria == GetTotal"
            @click="modalNewReport = true"
          >
            <span class="material-icons">check</span> Gen. report
          </button>

          <button class="validate-runner" v-else @click="modalNewReport = true">
            <span class="material-icons">check</span> Gen. report (partial)
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" v-if="modalGlobalComment">
    <div class="modal-global-comment">
      <div class="modal-top">
        <h1>New Global Comment</h1>
        <span
          class="material-icons close-btn"
          @click="modalGlobalComment = false"
          >close</span
        >
      </div>

      <div class="text-comment">
        <small>Comment</small>
        <textarea
          v-model="rapport[0].commentGlobal"
          placeholder="Your comment"
        ></textarea>
      </div>
    </div>
  </div>

  <div class="modal" v-if="modalNewReport">
    <div class="modal-new-report">
      <div class="modal-top">
        <h1 v-if="rapportImport != null">Update Report</h1>
        <h1 v-else>New Report</h1>
        <span class="material-icons close-btn" @click="modalNewReport = false"
          >close</span
        >
      </div>

      <div class="inputs">
        <div class="engine-version">
          <small>Next-Engine</small>
          <input
            type="text"
            v-model="rapport[0].engine"
            placeholder="Engine version..."
          />
        </div>
        <div class="server-version">
          <small>Next-Server</small>
          <input
            type="text"
            v-model="rapport[0].server"
            placeholder="Server version..."
          />
        </div>
        <div class="match-used">
          <small for="match-inp">Template</small>
          <input
            type="text"
            v-model="rapport[0].template"
            placeholder="Template name..."
          />
        </div>
        <button v-if="rapportImport != null" @click="validateRunner">
          <span class="material-icons">check</span>Update Report
        </button>

        <button v-else @click="validateRunner">
          <span class="material-icons">check</span>Generate Report
        </button>
        <small style="color: red; margin-top: 5px">{{ message }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import ScenesEdit from "../components/ScenesEdit.vue";
import RunnerView from "../components/RunnerView.vue";
export default {
  components: {
    ScenesEdit,
    RunnerView,
  },

  props: {
    rapportImport: String,
    rapportImportIndex: String,
  },

  data() {
    return {
      rapport: [
        {
          id_test: null,
          scenes: [],
          engine: "",
          server: "",
          template: "",
          commentGlobal: "",
        },
      ],
      test: this.$store.state.tests[this.$route.params.id],
      SceneSelected: null,
      date: new Date(),
      modalNewReport: false,
      message: "",
      modalGlobalComment: false,
      outsActivated: null,
      layersActivated: null,
    };
  },

  emits: ["addTest", "changeScene"],

  mounted: function () {
    if (this.rapportImport != null) {
      this.rapport = JSON.parse(this.rapportImport);
      console.log("RAPPORT", this.$store.getters.getRapports[4]);
    } else {
      this.rapport[0].id_test = this.$route.params.id;
      this.test.scenes.forEach((scene) => {
        this.rapport[0].scenes.push({
          name: scene.name,
          criterias: [],
          date:
            this.date.getDate() +
            "/" +
            (this.date.getMonth() + 1) +
            "/" +
            this.date.getFullYear(),
        });

        scene.criterias.forEach((criteria) => {
          this.rapport[0].scenes[
            this.test.scenes.indexOf(scene)
          ].criterias.push({
            criteria: criteria,
            valid: Boolean,
            comment: null,
          });
        });
      });
    }
  },

  methods: {
    selectScene: function (index) {
      this.SceneSelected = index;
      if (this.outsActivated != null && this.layersActivated != null) {
        console.log("Starting connection to WebSocket Server");
        var connection = new WebSocket("ws://127.0.0.1:5250/jamcp");

        connection.onmessage = function (event) {
          console.log(event);
        };
        var em = this;
        connection.onopen = function (event) {
          em.outsActivated.forEach((out, index) => {
            if (out != null) {
              if (em.layersActivated[index] != null) {
                console.log("LAYERS", em.layersActivated[index]);
                console.log(event);
                console.log(
                  "Successfully connected to the echo websocket server..."
                );
                console.log("OUT", index);
                connection.send(`{
                "channel": ${index + 1},
                "layer": ${em.layersActivated[index]},
                "command": "PLAY",
                "arguments": {
                        "clip": "empty"
                    }
                }
            }`);
              }
            }
          });
        };
      }
    },

    addValid: function (index) {
      this.rapport[0].scenes[this.SceneSelected].criterias[index].valid = true;
    },

    addReject: function (index) {
      this.rapport[0].scenes[this.SceneSelected].criterias[index].valid = false;
    },

    addComment: function (data) {
      this.rapport[0].scenes[this.SceneSelected].criterias[data.index].comment =
        data.comment;
    },

    getOuts: function (data) {
      this.outsActivated = data.outs;
      this.layersActivated = data.layers;
    },

    validateRunner: function () {
      if (
        this.rapport[0].engine == "" ||
        this.rapport[0].server == "" ||
        this.rapport[0].match == ""
      ) {
        this.message = "Please fill in all fields";
      } else {
        if (this.rapportImport != null) {
          this.$store.dispatch("updateRapport", {
            index: this.rapportImportIndex,
            rapport: this.rapport,
          });
          this.$router.push({
            name: "dashboard",
            params: { id: this.$route.params.id },
          });
        } else {
          this.$store.dispatch("addRapport", this.rapport);
          this.$router.push({
            name: "dashboard",
            params: { id: this.$route.params.id },
          });
        }
      }
    },
  },

  computed: {
    GetTotalValid: function () {
      var valid = 0;
      this.rapport[0].scenes.forEach((scene) =>
        scene.criterias.forEach((criteria) => {
          if (criteria.valid == true) {
            valid++;
          }
        })
      );
      return valid;
    },

    GetTotalReject: function () {
      var reject = 0;
      this.rapport[0].scenes.forEach((scene) =>
        scene.criterias.forEach((criteria) => {
          if (criteria.valid == false) {
            reject++;
          }
        })
      );
      return reject;
    },

    GetTotalCriteria: function () {
      var total = 0;

      this.rapport[0].scenes.forEach((scene) =>
        scene.criterias.forEach((criteria) => {
          if (criteria.valid == true || criteria.valid == false) {
            total++;
          }
        })
      );
      return total;
    },

    GetTotal: function () {
      var total = 0;

      this.rapport[0].scenes.forEach((scene) =>
        scene.criterias.forEach(() => {
          total++;
        })
      );
      return total;
    },
  },
};
</script>

<style lang="scss">
.launch {
  display: flex;
  flex-direction: column;
  height: 100%;

  .infos {
    background: #202020;
    width: 100%;
    height: 96px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .global-comment-btn {
      height: 58px;
      margin-right: 10px;
      border: none;
      background: #0064c8;
      color: white;
      border-radius: 5px;
    }

    .infos-name {
      display: flex;
      align-items: center;

      .logo-test {
        width: 64px;
        height: 64px;
        border-radius: 5px;
        margin-left: 10px;
        background: #0064c8;
      }

      .test-infos {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        h1 {
          margin: 0;
        }

        .infos-p {
          display: flex;
          align-items: center;
          p {
            margin: 0;
            line-height: 20px;
            color: lightgray;
            font-size: 0.8em;
            margin-right: 10px;
            display: flex;
            align-items: center;
            span {
              margin-right: 5px;
              color: #0064c8;
              font-size: 15px;
            }
          }
        }
      }
    }

    .btns-test {
      display: flex;
      button {
        margin-right: 10px;
        cursor: pointer;
        height: 58px;
      }

      .btn-edit-test {
        border: none;
        border-radius: 5px;
        background: #0064c8;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        width: 160px;
        svg {
          width: 25px;
          margin-right: 10px;
        }
      }

      .btn-delete {
        width: 58px;
        background: transparent;
        border: 1px solid #dc3548;
        border-radius: 5px;
      }

      button:hover {
        opacity: 90%;
      }
    }
  }

  .bottom-launch {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 100%;
    margin-top: 24px;
    grid-gap: 24px;

    .criterias-launch {
      background: #202020;
      border-radius: 5px;

      .no-scene {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        opacity: 50%;

        span {
          font-size: 4em;
        }
        h2 {
          opacity: 50%;
        }
      }
    }

    .runner-criterias {
      display: grid;
      grid-template-rows: 5fr 1fr;
      grid-gap: 24px;

      .bottom-runner-validate {
        width: 100%;
        height: 100%;
        background: #202020;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          display: flex;
          align-items: center;
        }

        div span {
          margin-right: 5px;
          font-size: 3em;
        }

        .valid {
          color: #2ebc70;
        }

        .reject {
          color: #dc3548;
          margin-left: 15px;
        }

        .validate-runner {
          display: flex;
          align-items: center;
          height: 56px;
          background: #2ebc70;
          border: none;
          border-radius: 5px;
          width: 210px;
          color: white;
          justify-content: center;
          font-size: 16px;
          margin-right: 15px;
        }
      }
    }
  }
}

.modal-global-comment {
  height: 50vh;
  width: 50vw;
  position: relative;
  padding: 20px;
  background: #202020;
  border-radius: 5px;
  box-sizing: border-box;

  .text-comment {
    background: transparent;
    border: 2px solid white;
    border-radius: 5px;
    width: 100%;
    height: 60%;
    position: relative;

    small {
      background: #202020;
      color: white;
      position: absolute;
      top: -10px;
      padding: 0 5px;
      left: 15px;
    }

    textarea {
      background: transparent;
      border: none;
      position: relative;
      left: 0;
      top: 0;
      bottom: 0;
      width: 99%;
      height: 94%;
      outline: none;
      padding-top: 10px;
      padding-left: 10px;
      color: white;
    }
  }
}

.modal-new-report {
  height: 50vh;
  position: relative;
  width: 30vw;
  background: #202020;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    position: absolute;
    top: 15px;
    left: 15px;
    margin: 0;
  }

  .inputs {
    div {
      background: transparent;
      border: 1px solid white;
      border-radius: 5px;
      width: 300px;
      height: 45px;
      outline: none;
      margin-bottom: 15px;

      input {
        background: transparent;
        border: none;
        color: white;
        width: 100%;
        height: 100%;
        top: -20px;
        outline: none;
        position: relative;
        padding-left: 10px;
      }

      small {
        position: relative;
        top: -10px;
        background: #202020;
        padding: 0 5px;
        left: 5px;
      }
    }
  }

  button {
    width: 300px;
    height: 45px;
    background: #2ebc70;
    border: none;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 5px;
    }
  }
}
</style>
