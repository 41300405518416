//--------------------------------------- // TEST-TOOL-POC
//---------------------------------------
<template>
  <ListTests @addTest="newModal = true" @changeScene="noScene = !noScene" />
  <div class="right-side" v-if="$route.path == '/'">
    <div class="nothing">
      <span class="material-icons">do_not_disturb_on</span>
      <h2>No selected test</h2>
    </div>
  </div>

  <div class="right-side" v-else>
    <router-view />
  </div>
</template>

<script>
import ListTests from "./components/ListTests.vue";
export default {
  components: {
    ListTests,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  font-family: "Raleway", sans-serif;
}

#app {
  display: grid;
  background: #404040;
  grid-template-columns: 1fr 3fr;
  grid-gap: 24px;
  height: 100vh;

  .right-side {
    border-radius: 5px;
    color: white;
    margin: 24px 24px 24px 0;
  }

  ::-webkit-scrollbar {
    background: transparent;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba($color: white, $alpha: 0.3);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba($color: white, $alpha: 0.5);
  }

  ::-webkit-resizer,
  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-corner {
    display: none;
  }

  button {
    cursor: pointer;
  }

  button:hover {
    opacity: 90%;
  }

  .nothing {
    background: #101010;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      font-size: 4em;
      opacity: 50%;
    }

    h2 {
      opacity: 50%;
    }
  }

  .modal {
    position: fixed;
    z-index: 100;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba($color: #1111, $alpha: 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    .modal-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        display: flex;
        align-items: center;
      }

      .close-btn {
        font-size: 26px;
        cursor: pointer;
        background: #111111;
        border-radius: 5px;
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
  }
}
</style>
