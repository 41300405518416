<template>
  <div class="history">
    <h1>Past tests</h1>

    <div class="no-scene" v-if="SelectedRapports == 0">
      <h1>No past tests yet</h1>
    </div>

    <div class="history-list" v-else>
      <div
        class="test-history"
        v-for="rapport in SelectedRapports"
        :key="rapport"
      >
        <div class="top-test-history">
          <span v-if="rapport[0].engine != ''"
            ><strong>{{ rapport[0].scenes[0].date }}</strong> | E:
            <strong>{{ rapport[0].engine }}</strong> | S:
            <strong>{{ rapport[0].server }}</strong> |
            <strong>{{ rapport[0].template }}</strong></span
          >
          <span v-else
            ><strong>{{ rapport[0].scenes[0].date }}</strong></span
          >
          <div class="btns-history">
            <button
              class="btn-modify-report"
              v-if="getTotal(rapport[0].scenes) != 100"
              @click="
                $router.push({
                  name: 'runner',
                  params: {
                    id: $route.params.id,
                    rapportImport: JSON.stringify(rapport),
                    rapportImportIndex:
                      $store.getters.getRapports.indexOf(rapport),
                  },
                })
              "
            >
              Modify
            </button>

            <button class="btn-modify-report" v-else style="background: grey">
              Modify
            </button>

            <button
              @click="
                $router.push({
                  name: 'report',
                  params: {
                    id: $store.getters.getRapports.indexOf(rapport),
                    indexTest: indexTest,
                  },
                })
              "
            >
              Details
            </button>
          </div>
        </div>
        <div class="test-infos">
          <div
            class="pourcentage"
            v-if="getPourcentage(rapport[0].scenes) == 100"
          >
            <p class="passed-p">
              <span class="material-icons">check_circle</span>PASSED
            </p>
            <h4>({{ getPourcentage(rapport[0].scenes) }}%)</h4>
          </div>

          <div class="pourcentage" v-else>
            <p class="fail-p">
              <span class="material-icons">do_not_disturb</span>FAIL
            </p>
            <h4>({{ getPourcentage(rapport[0].scenes) }}%)</h4>
          </div>

          <div class="infos-descr">
            <div class="descr-valid">
              <span class="material-icons">check_circle_outline</span>
              {{ getValidity(rapport[0].scenes, true) }}
            </div>
            <div class="descr-reject">
              <span class="material-icons">do_not_disturb</span>
              {{ getValidity(rapport[0].scenes, false) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["indexTest"],

  data() {
    return {
      rapports: this.$store.getters.getRapports,
    };
  },

  methods: {
    getPourcentage: function (scenes) {
      var valid = 0;
      var total = 0;

      scenes.forEach((scene) => {
        scene.criterias.forEach((criteria) => {
          if (criteria.valid) {
            valid++;
            total++;
          } else {
            total++;
          }
        });
      });

      return ((valid / total) * 100).toFixed(1);
    },

    getValidity: function (scenes, bool) {
      var valid = 0;
      var reject = 0;

      scenes.forEach((scene) => {
        scene.criterias.forEach((criteria) => {
          if (criteria.valid) {
            valid++;
          } else {
            reject++;
          }
        });
      });

      if (bool) {
        return valid;
      } else {
        return reject;
      }
    },

    getTotal: function (scenes) {
      var total = 0;
      var valid = 0;

      scenes.forEach((scene) => {
        scene.criterias.forEach((criteria) => {
          total++;
          if (typeof criteria.valid != "undefined") {
            valid++;
          }
        });
      });

      return ((valid / total) * 100).toFixed(1);
    },
  },

  computed: {
    SelectedRapports: function () {
      return this.rapports.filter(
        (rapport) => rapport[0].id_test == this.$route.params.id
      );
    },
  },
};
</script>

<style lang="scss">
.history {
  height: 100%;
  width: 100%;
  background: #202020;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  .no-scene {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    opacity: 50%;
  }

  h1 {
    margin-left: 10px;
  }

  .history-list {
    margin: 0 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 2px solid #0064c8;
    position: absolute;
    overflow-y: overlay;
    bottom: 0;
    top: 100px;

    .test-history {
      height: 100px;
      width: 93%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      border-radius: 5px;

      .top-test-history {
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          margin: 0;
          opacity: 50%;
          font-weight: 400;
          font-size: 14px;
        }

        .btns-history {
          button {
            border: none;
            background: #0064c8;
            color: white;
            border-radius: 2px;
            padding: 5px;
          }
          .btn-modify-report {
            margin-right: 5px;
          }
        }
      }

      .test-infos {
        height: 56px;
        width: 100%;
        background: #101010;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pourcentage {
          margin-left: 40px;
          display: flex;
          align-items: center;
          font-size: 1.1em;

          p {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }

          .passed-p {
            color: #2ebc70;
          }

          .fail-p {
            color: #dc3548;
          }

          span {
            margin-right: 10px;
            font-size: 1.5em;
          }
        }

        .infos-descr {
          display: flex;
          align-items: center;

          div {
            display: flex;
            align-items: center;
            margin-right: 15px;

            span {
              margin-right: 5px;
            }
          }

          .descr-valid {
            color: #2ebc70;
          }

          .descr-reject {
            color: #dc3548;
          }
        }
      }
    }
  }
}
</style>
